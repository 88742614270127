'use strict';

/*******************************************************************************************/
class UxrEeSelectSource extends React.Component {
	constructor(props) {
		super(props);
		this.defaultValue = '';
		this.state = {
			'value': this.props.value ||this.props.defaultValue || this.defaultValue,
		};
		
		this.promiseOptions = this.promiseOptions.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		
		return (
			<div className="MVT-ctrl=MVT_terminal=FformLine" {...this.props.htmlProps}>
				<UxrEe_.Select.Async loadOptions={this.promiseOptions} multi={this.props.multi} clearable={this.props.clearable} value={this.state.value.options} onChange={this.onChange} />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(value) {
		value = {options:value}; //Array is a "reserved word"
		this.setState({
			'value': value
		})
		this.props.onChange(this, {value});
	}
	
	/*---------------------------------------------------------------------*/
	promiseOptions(input) {
	    let o = this;
	    
	    return E.intermediateLayer.dataImage
	        .fetch(E.zeroNodeId)
	        .then(function(zeroDI) {
				let diPromisses = [];
				let associations = zeroDI.values.data.entityTypes.getAll();
				_.forEach(associations, function(each) {
					diPromisses.push(each.promise());
				});
	            return Promise.all(diPromisses);
	        })
	        .then(function(listDIs) {
	            let relevantListDIs = listDIs.filter(function(listDI) {
					let intersection = _.intersection(
						listDI.node.data.typeDescriptor,
						o.props.filter
					);
                    return intersection.length > 0;
	            });
	            return relevantListDIs;
	        })
	        .then(function(listDIs) {
				listDIs = _.keyBy(listDIs, 'node.id');
				
	            let dataImages = {};
	            _.forEach(listDIs, function(entityTypeDI) {
                    _.assign(dataImages, entityTypeDI.values.data.items.getAll());
	            });
				let diPromisses = [];
				_.forEach(dataImages, function(each) {
					diPromisses.push(each.promise());
				});
				
				diPromisses.push(listDIs); //piggybacking
	            return Promise.all(diPromisses);
	        })
	        .then(function(itemDIs) {
	        	let listDIs = itemDIs.pop(); //piggybacked
	            let options = [];
	            
	            _.forEach(itemDIs, function(itemDI) {
	            	let listDI = listDIs[itemDI.node.data.entityType.id];
	            	let values = listDI.node.data.model.readout(itemDI);
	                let humanId = listDI.node.capabilities.humanId(itemDI, values);
	                
	                options.push({
	                    'value': itemDI.node.id,
	                    'label': humanId,
	                });
	            });
	            
	            return {
	                options: options,
	                complete: true,
	            };
	        })
	        ;
	}
} //class
